<template>
  <div class="home">
    <h2 class="m-2">Здравствуйте, {{ $store.state.user.firstName }}</h2>
  </div>
</template>

<script>
export default {
  name: "Index"
};
</script>
